<template>
  <div></div>
</template>
<script>
export default {
  name: "FilterDate",
  props: {
    column: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
