var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip),expression:"getTooltip"}],class:_vm.getClass,style:(_vm.getStyle),attrs:{"data-label":_vm.column.th}},[(_vm.getLoadingStatus)?_c('TableSkeletonLoader',{class:_vm.getLoadingClass}):(_vm.column.type == 'image_upload')?_c('TableImageUpload',{attrs:{"row-index":_vm.rowIndex,"row":_vm.rowData,"value":_vm.cellValue}}):(_vm.column.type == 'image')?_c('TableImage',{attrs:{"source":_vm.imagePath,"hidden":true}}):(_vm.column.type == 'image_url')?_c('TableImage',{attrs:{"source":_vm.cellText,"width":_vm.column.image_width || '80',"type":'picture'}}):(_vm.column.type == 'list')?_c('TableList',{attrs:{"class-name":_vm.cellText,"data-title":_vm.cellTitle}}):(_vm.column.type == 'boolean')?_c('TableSwitch',{attrs:{"row":_vm.rowData,"column":_vm.column,"value":_vm.cellValue}}):(_vm.column.type == 'boolean_disabled')?_c('TableBooleanDisabled',{attrs:{"value":_vm.cellValue}}):(_vm.column.type == 'stock_status')?_c('StockStatus',{attrs:{"value":_vm.cellValue}}):(_vm.column.type == 'quantity')?_c('TableQuantity',{attrs:{"row":_vm.rowData},model:{value:(_vm.cellValue),callback:function ($$v) {_vm.cellValue=$$v},expression:"cellValue"}}):(_vm.column.type == 'input')?_c('TableInput',{attrs:{"row":_vm.rowData,"row-index":_vm.rowIndex,"size":_vm.column.size || '',"filter-number":_vm.column.filterNumber},model:{value:(_vm.cellValue),callback:function ($$v) {_vm.cellValue=$$v},expression:"cellValue"}}):(
      _vm.column.type == 'currency' &&
      ((_vm.rowData.hasOwnProperty('converted_price') &&
        _vm.rowData.hasOwnProperty('converted_price_currency')) ||
        (_vm.rowData.status && _vm.rowData.status == 'error'))
    )?_c('TablePriceTooltip',{attrs:{"row":_vm.rowData,"current-price":_vm.rowData.price,"current-price-currency":_vm.rowData.currency_code.code,"converted-price":_vm.rowData.converted_price,"converted-price-currency":_vm.rowData.converted_price_currency}}):(_vm.column.type == 'price')?_c('TablePrice',{attrs:{"column":_vm.column,"size":_vm.column.size || ''},model:{value:(_vm.cellValue),callback:function ($$v) {_vm.cellValue=$$v},expression:"cellValue"}}):(_vm.column.type == 'select')?_c('TableSelect',{attrs:{"row":_vm.rowData,"options":_vm.column.options},model:{value:(_vm.cellValue),callback:function ($$v) {_vm.cellValue=$$v},expression:"cellValue"}}):(_vm.column.type == 'star')?_c('TableStar',{attrs:{"value":_vm.cellValue}}):(_vm.column.type == 'total_price')?_c('TableTotalPrice',{attrs:{"row":_vm.rowData,"value":_vm.cellValue}}):(_vm.column.type == 'marketplace_list')?_c('TableMarketplaceList',{attrs:{"items":_vm.cellValue}}):(_vm.column.type == 'language')?_c('TableLanguagelist',{staticClass:"ml-2",attrs:{"items":_vm.cellValue}}):(_vm.column.type == 'split_count')?_c('TableSplitCount',{attrs:{"row":_vm.rowData}}):(_vm.column.type == 'table_badge')?_c('TableBadge',{attrs:{"value":_vm.cellValue}}):(_vm.column.type == 'editable_currency')?_c('TableEditPrice',{attrs:{"row":_vm.rowData},model:{value:(_vm.cellValue),callback:function ($$v) {_vm.cellValue=$$v},expression:"cellValue"}}):(_vm.column.type == 'html')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.cellText)}}):[(
        _vm.column.th == _vm.productColumn &&
        _vm.productColumn != null &&
        _vm.rowData.hasOwnProperty('main_product_id')
      )?[_c('a',{staticClass:"table-product-name",style:({
          maxWidth: _vm.column.maxWidth || '100%',
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
        }),attrs:{"href":("products/detail/" + (_vm.rowData.id))}},[_vm._v(" "+_vm._s(_vm.cellText)+" ")]),_c('a',{style:({
          marginLef: '10px',
          maxWidth: _vm.column.maxWidth || '100%',
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
        }),attrs:{"href":"" + (_vm.config['site.url']) +
          (_vm.config['site.slug_prefix'] == '1' ? '/urun/' : '/') +
          "" + (_vm.rowData.slug),"target":"_blank"}},[_c('i',{staticClass:"fas fa-external-link-alt"})])]:(_vm.column.name == 'order_no')?[_c('div',{staticStyle:{"max-width":"330px"}},[_c('p',{staticClass:"table-order-p"},[_vm._v(_vm._s(_vm.cellText))])])]:[_vm._v(" "+_vm._s(_vm.cellText)+" ")]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }