<!-- 
	Vue Datatable 
	Description: Vue Component for datatable with search, sorting, editing and pagination
	Author: Antonio Okoro 
	Version: 1.0.0 
-->
<template>
  <div
    v-bind:class="[
      'data-table',
      fixedHead ? 'table-fixed-head custom-scrollbar' : '',
    ]"
  >
    <div class="data-table-inner">
      <div class="row mb-3 data-table-control" v-if="header">
        <div class="col-auto" v-if="limitable">
          <label class="custom-label"> Kayıt Sayısı </label>
          <select class="custom-select ml-3" v-model="localPerPage">
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </select>
        </div>
        <div
          v-bind:class="['col-auto hide-on-print', limitable ? 'pl-0' : '']"
          v-if="exportables && exportables.length"
        >
          <div class="d-flex align-items-stretch h-100">
            <Exports v-bind:data="exportables" />
          </div>
        </div>
        <div class="col-12 col-md-auto ml-auto mt-3 mt-md-0">
          <div class="row mx-auto">
            <div
              class="row col-md-auto mb-md-0 w-sm-25 mb-3"
              v-if="multiLanguage"
            >
              <Language
                v-model="languageFilter"
                v-bind:stateName="stateName"
                @FilterLanguage="FilterLanguage"
              />
            </div>
            <div
              class="row align-items-center"
              v-if="searchable || detailSearch"
            >
              <div class="col" v-if="searchable">
                <input
                  type="text"
                  class="form-control"
                  v-bind:placeholder="searchText"
                  @keyup="onSearch(query)"
                  v-model="query"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto ml-auto" v-if="showFilters">
          Filters:
          <div class="table-filters d-inline-block">
            <div
              class="table-filter"
              v-for="(option, index) in filters"
              v-bind:key="index"
              @click="filter(option)"
            >
              <span>{{ option.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="position-relative"
        v-bind:class="{ 'table-responsive': responsive }"
      >
        <WeLoading
          v-if="loading || ajaxLoading"
          class="bg-white-transparent-5"
          style="z-index: 9999"
        />
        <table
          class="table border"
          :class="{ straight: !breakWords, 'table-hover': !!onClick }"
        >
          <thead class="thead-light">
            <tr>
              <th v-if="draggable">
                <i class="fas fa-ellipsis-v" style="margin-left: -2px"></i>
              </th>
              <!-- Display Checkboxes If Requested -->
              <th v-if="selectable && items.length" width="1%">
                <label
                  v-if="allSelect"
                  class="custom-control custom-checkbox mb-0"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    @change="selectAll"
                    :checked="
                      selected.length > 0 && selected.length == items.length
                    "
                  />
                  <span class="custom-control-label"></span>
                </label>
              </th>

              <!-- Display Index If Requested -->
              <th
                v-if="index"
                @click="sortIndex()"
                class="sortable"
                :class="{
                  sort: sortColumn == '#',
                  asc: sortColumn == '#' && asc,
                  desc: sortColumn == '#' && !asc,
                }"
              >
                #
              </th>
              <!-- Display All Parsed Headers -->
              <th
                v-bind:key="index"
                v-for="(th, index) in headers"
                @click="sort(th.name, th.sortable)"
                v-bind:width="th.width ? th.width : 'auto'"
                :class="{
                  sortable: th.sortable,
                  sort: sortColumn == th.name,
                  asc: sortColumn == th.name && asc,
                  desc: sortColumn == th.name && !asc,
                }"
              >
                <i
                  v-if="th.icon"
                  v-bind:class="th.icon"
                  v-tooltip.bottom="th.iconTooltip"
                ></i>
                <span v-else>{{ th.th }}</span>
              </th>
              <!-- Display Actions If Provided -->
              <th width="150" class="hide-on-print" v-if="actions.length">
                İşlemler
              </th>
            </tr>
            <!-- Column Filters -->
            <template v-if="columnSearch">
              <Filters
                class="border"
                v-for="column in headers"
                v-bind:key="column.name"
                v-bind:column="column"
                v-on:on-search="onColumnSearch"
              />
            </template>
            <!-- Column Filters -->
          </thead>
          <tbody v-if="paginatedItems.length">
            <!-- Loop Through All Parsed and Paginated Items -->
            <Rows
              v-bind:draggable="draggable"
              v-for="(item, i) in paginatedItems"
              v-bind:key="i"
              v-bind:index="i"
              v-bind:selectable="selectable"
              v-bind:selected="selected"
              v-bind:select-limit="selectLimit"
              v-bind:rows.sync="paginatedItems"
              v-bind:productColumn="productColumn"
              v-on:on-checked="select(item)"
              v-on:image-upload="updateModal(i)"
            />
          </tbody>
          <tbody v-else>
            <!-- Display Empty Message If No Items Are Rendered -->
            <tr>
              <td
                align="center"
                :colspan="
                  headers.length +
                  (actions.length ? 1 : 0) +
                  (index ? 1 : 0) +
                  (draggable ? 1 : 0)
                "
              >
                Gösterilecek Kayıt Yok
              </td>
            </tr>
          </tbody>
          <tfoot v-if="this.$slots.footer || showTableTotal">
            <slot name="footer"></slot>
            <tr
              v-if="showTableTotal"
              v-bind:class="{ 'hide-on-print': hideTableTotalPrint }"
            >
              <td v-bind:colspan="headers.length" class="border-0 text-right">
                <div class="d-inline-block">
                  <span class="font-weight-bold">
                    <template v-if="paginatable && pages && pages > 0">
                      {{ tableTotalText }} :
                      {{ getCurrentTotal | moneyFormat }} /
                      {{ tableTotal | moneyFormat }}
                    </template>
                    <template v-else>
                      {{ tableTotalText }} : {{ tableTotal | moneyFormat }}
                    </template>
                  </span>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="row table-bottom" v-if="footer">
        <div class="col-12 col-md" v-if="pageDetails && paginatedItems.length">
          <div class="showing">
            <b>{{ getTotalRecord | numberFormat }}</b>

            kayıttan

            <b>{{
              paginatedItems.length
                ? localPerPage * (currentPage - 1) + 1
                : 0 | numberFormat
            }}</b>
            -
            <b>{{
              (localPerPage * (currentPage - 1) + paginatedItems.length)
                | numberFormat
            }}</b>

            arasındaki kayıtlar gösteriliyor
          </div>
        </div>
        <div
          class="col-12 col-md-auto"
          v-if="paginatedItems.length && paginatable && paginateLinks.length"
        >
          <div class="row justify-content-end">
            <!-- Go To Page -->
            <div
              class="col-auto col-lg-3 mb-3 mb-md-0"
              v-if="paginatedItems && pages > 10"
            >
              <div class="input-group">
                <input
                  style="height: 35px"
                  type="text"
                  class="form-control"
                  v-on:focus="$event.target.select()"
                  v-on:keyup.enter="goToPage"
                  v-model="pageNumber"
                />
                <div class="input-group-append">
                  <span
                    class="btn btn-primary"
                    style="height: 35px"
                    v-on:click="goToPage"
                    ><i class="fas fa-arrow-right"></i
                  ></span>
                </div>
              </div>
            </div>
            <!-- Go To Page -->

            <!-- Paginate -->
            <div class="col-12 col-md-auto">
              <ul class="pagination">
                <li class="page-item" v-if="pages && currentPage !== 1">
                  <span class="page-link" v-on:click="prev"
                    ><i class="fas fa-chevron-left"></i
                  ></span>
                </li>
                <li
                  class="page-item"
                  v-bind:key="item.page"
                  v-for="item in paginateLinks"
                  :class="{
                    active: currentPage == item.page,
                  }"
                >
                  <a class="page-link" v-on:click="paginate(item.page)">{{
                    item.page
                  }}</a>
                </li>
                <li class="page-item" v-if="pages && currentPage < pages">
                  <span class="page-link" v-on:click="next"
                    ><i class="fas fa-chevron-right"></i
                  ></span>
                </li>
              </ul>
            </div>
            <!-- Paginate -->
          </div>
        </div>
      </div>
    </div>
    <WeModal v-if="imageUpload && showModal" v-on:close="onCloseModal">
      <div slot="body">
        <WeImageUploader
          v-model="getModal"
          v-bind:size-limit-message="sizeLimitMessage"
          v-on:on-remove="$emit('image-removed', paginatedItems[showIndex].row)"
        />
      </div>
      <div slot="footer">
        <WeSubmitButton v-on:submit="onCloseModal" class="mr-2" />
      </div>
    </WeModal>
  </div>
</template>

<script>
// import toastr from "toastr";
import Axios from "axios";
Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// import moment from "moment";
// import Vue from 'vue';
import Rows from "./components/Rows/Index";
import Filters from "./components/Filters/Index";
import Exports from "./components/Exports/Index";
import Language from "./components/Filters/components/FilterLanguage/Index";
export default {
  data() {
    return {
      showIndex: -1,
      // Show Modal
      showModal: false,

      // Go to page number
      pageNumber: 1,
      // Current Page Number In Pagination
      currentPage: 1,
      // Current Page Items
      paginatedItems: [],
      // Sort Order
      asc: "asc",
      // Column For Sorting
      sortColumn: null,
      // Search Query
      query: "",
      // Table Headers
      headers: [],
      // Mapped Data
      items: [],
      // Mapped Action Buttons
      buttons: [],

      // Items To Be Displayed
      renderedItems: [],
      // Selected Items
      selected: [],

      searchQuery: null,

      localData: {
        totalPage: this.totalPage,
        totalRecord: this.totalRecord,
      },

      localPerPage: this.itemsPerPage,
      languageFilter: null,
    };
  },
  props: {
    // =================================
    // Ajax
    // Datatables Ajax Uses Axios
    // Make Sure Axios Is Added As An NPM Dependency
    // =================================
    // Ajax URL
    componentName: {
      type: String,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: () => 25,
    },
    productColumn: {
      type: String,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default: () => "Tabloda Ara",
    },
    rowClass: {
      type: String,
      default: () => "",
    },
    url: {
      type: String,
      default: () => "",
    },
    // Whether Or Not To Use Ajax
    ajax: {
      type: Boolean,
      default: () => false,
    },
    // Ajax Headers
    AjaxHeaders: {
      type: Object,
      default: () => {},
    },

    // Search Delay
    searchDelay: {
      type: Number,
      default: () => 1000,
    },

    searchLength: {
      type: Number,
      default: () => 3,
    },
    // Table Items
    data: {
      type: Array,
      default: () => [],
    },
    // Action Buttons For Each Item
    actions: {
      type: Array,
      default: () => [],
    },
    // Columns and Appropriate Data Assigment
    columns: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    // Whether or Not Items Should Be Indexed
    index: {
      type: Boolean,
      default: () => true,
    },
    // Set Loading Status
    loading: {
      type: Boolean,
      default: () => false,
    },
    // Click Events For Each Cell
    onClick: {
      type: Function,
      default: () => {},
    },
    // Whether Or Not The Table Should Be Allowed To Break Elements
    breakWords: {
      type: Boolean,
      default: () => false,
    },
    // Whether Or Not The Header Should Be Visible
    header: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not The Footer Should Be Visible
    footer: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not Searching Should Be Available
    searchable: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not Page Limitation Should Be Changeable
    limitable: {
      type: Boolean,
      default: () => true,
    },
    // Fixed Head
    fixedHead: {
      type: Boolean,
      default: () => false,
    },
    // Whether Or Not Details Should Be Visible
    pageDetails: {
      type: Boolean,
      default: () => true,
    },
    // Whether Or Not The Results Should Be Paginatable
    paginatable: {
      type: Boolean,
      default: () => true,
    },

    // Whether Or Not Items Should Be Selctable
    selectable: {
      type: Boolean,
      default: () => false,
    },
    exportData: {
      default: null,
    },
    allSelect: {
      type: Boolean,
      default: () => false,
    },
    selectLimit: {
      default: "25",
    },
    imageUpload: {
      type: Boolean,
      default: () => false,
    },
    responsive: {
      type: Boolean,
      default: () => true,
    },
    sizeLimitMessage: {
      default: "En fazla 1024 KB boyutunda dosya yükleyebilirsiniz.",
    },
    // Filter Column
    columnSearch: {
      type: Boolean,
      default: false,
    },
    detailSearch: {
      default: false,
    },
    allowSortIndex: {
      default: true,
    },
    totalPage: {
      default: 0,
    },
    totalRecord: {
      default: 0,
    },
    // Loading State For Ajax Requests
    ajaxLoading: {
      default: false,
    },
    tableResult: {},

    exportables: {
      type: Array,
      default: () => [],
    },

    showTableTotal: {
      type: Boolean,
      default: false,
    },
    hideTableTotalPrint: {
      type: Boolean,
      default: false,
    },
    tableTotalText: {
      type: String,
      default: "Toplam Tutar",
    },
    tableTotal: {
      type: Number,
      default: 0,
    },
    totalColumn: {
      type: String,
      default: "",
    },
    fetchOnMount: {
      type: Boolean,
      default: true,
    },
    multiLanguage: {
      type: Boolean,
      default: false,
    },
    stateName: {
      type: String,
      default: "",
    },
  },
  components: {
    Rows,
    Filters,
    Exports,
    Language,
  },
  methods: {
    updateModal(i) {
      this.showIndex = i;
      this.showModal = true;
    },
    onCloseModal() {
      this.showModal = false;
    },
    goToPage() {
      if (this.pageNumber > this.pages) {
        this.pageNumber = this.pages;
      } else if (this.pageNumber <= 0) {
        this.pageNumber = 1;
      }

      this.currentPage = this.pageNumber;
    },
    // Navigate To Provided Page
    // Arguments
    // 	Page: int
    paginate(page) {
      if (this.ajax && this.loading) return;
      this.currentPage = page;
    },
    cacheCurrentPage(page) {
      if (this.componentName) {
        localStorage.setItem(`last_${this.componentName}_current_page`, page);
      }
    },
    // Navigate To Next Page
    next() {
      this.currentPage =
        this.currentPage >= this.getTotalRecord ? 0 : (Number(this.currentPage) + 1);
    },
    // Navigate To Previous Page
    prev() {
      this.currentPage =
        this.currentPage <= 0 ? this.getTotalRecord : (Number(this.currentPage) - 1);
    },
    // Navigate To Last Page
    end() {
      this.currentPage = this.getTotalRecord;
    },
    // Navigate To First Page
    start() {
      this.currentPage = 1;
    },
    onSearch(text) {
      this.doSearch(text, this.searchDelay);
    },
    doSearch(text, timeout, column = null) {
      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= this.searchLength) {
          this.emitSearch(text, localTimeout, column);
        }
      } else {
        this.emitSearch("", 0);
      }
    },
    emitSearch(text, timeout, column) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        clearTimeout(this.timer);

        this.search(text, column);
      }, timeout);
    },
    // Search Through Items With Provided Search Query
    // Arguments
    // 	Query: string
    search(query, column) {
      var items = this.mapItems(this.items);
      this.searchQuery = query;

      if (!this.ajax) {
        let retval = items.filter((item) => {
          if (item.row.hasOwnProperty("deleted") && item.row.deleted === 1) {
            return;
          }

          var found = false;

          if (!column) {
            // Search In Mapped Data
            item.details.forEach((detail) => {
              // Cancel If Original And Processed Value Are NULL
              if (detail.value == null || detail.rendered == null) {
                return;
              }
              // If Found In Original Value
              if (detail.value.toLocaleString().match(new RegExp(query, "i"))) {
                found = true;
              }

              // If Found In Processed Value
              if (
                detail.rendered.toLocaleString().match(new RegExp(query, "i"))
              ) {
                found = true;
              }
            });

            // Search In Provided Data
            for (var columnData in item.row) {
              if (!item.row[columnData]) {
                continue;
              }

              if (
                item.row[columnData]
                  .toLocaleString()
                  .match(new RegExp(query, "i"))
              ) {
                found = true;
              }
            }
          } else {
            if (item.row[column] === null) {
              found = false;
            } else if (
              item.row[column]
                .toLocaleLowerCase()
                .toLocaleString()
                .match(new RegExp(query, "i"))
            ) {
              found = true;
            }
          }

          return found;
        });
        this.renderedItems = retval;
      } else {
        this.start();
        this.makeRequest();
      }

      this.sortIndex(false);
    },
    FilterLanguage(lang) {
      this.languageFilter = lang;
      if (this.ajax) this.makeRequest();
      else this.$emit("FilterLanguage", this.languageFilter);
    },
    // Sort Items By Specified Column and Order
    // Arguments
    // 	Column: String
    // 	Order: String [asc, desc]
    sort(column, sortable = true) {
      if (!sortable) return;
      if (!this.ajax) {
        this.renderedItems = this.renderedItems.sort((a, b) => {
          var detailx = a.details.find((detail) => detail.name == column);
          var x = detailx.rendered;
          if (!x) {
          }
          x = typeof x == "string" ? x.toLowerCase() : x;
          var detaily = b.details.find((detail) => detail.name == column);
          var y = detaily.rendered;
          if (!x) {
          }
          y = typeof y == "string" ? y.toLowerCase() : y;
          const collator = new Intl.Collator("tr", {
            numeric: true,
            sensitivity: "base",
          });

          // equal -> items sort equally
          if (x === y) {
            return 0;
          } else if (x === null) {
            // nulls -> sort after anything else
            return 1;
          } else if (y === null) {
            return -1;
          } else {
            return collator.compare(x, y);
          }
        });
      }
      if (column !== this.sortColumn) {
        this.asc = true;
      } else {
        this.asc = !this.asc;
      }

      if (!this.asc && !this.ajax) {
        this.renderedItems = this.renderedItems.reverse();
      }
      this.sortColumn = column;

      this.start();

      if (this.ajax) this.makeRequest();
    },

    sortIndex(asc) {
      // this.renderedItems = this.renderedItems.sort((a, b) => {
      //   var indexA = a.index;
      //   var indexB = b.index;
      //   return indexA > indexB ? 1 : -1;
      // });
      // this.asc = this.sortColumn == "#" ? !this.asc : true;

      // if (asc != undefined) {
      //   if (!asc) {
      //     this.renderedItems = this.renderedItems.reverse();
      //   }
      // } else {
      //   if (!this.asc) {
      //     this.renderedItems = this.renderedItems.reverse();
      //   }
      // }

      this.sortColumn = "#";

      this.start();
    },

    filter(filter) {
      var filterValue = filter.value,
        filterColumn = filter.name;

      var items = this.mapItems(this.items);
      var filtered = items.filter((item, index) => {
        var column = item.details.find(
          (column, i) => column.name == filterColumn
        );
        if (!column) {
          return false;
        }
        // If Value Type Is A Custom Function
        if (filterValue.constructor.toString().match(/Function/)) {
          if (filterValue(item.row, column.value, index)) {
            return true;
          }
        } else if (
          column.value == filterValue ||
          column.rendered == filterValue
        ) {
          return true;
        }
        return false;
      });

      this.renderedItems = filtered;
      this.start();

      this.sortIndex(false);
    },

    getHeaders() {
      this.headers = this.columns.map((item) => ({
        name: item.name,
        th: item.th,
        sortable: item.hasOwnProperty("sortable") ? item.sortable : true,
        width: item.width,
        maxWidth: item.maxWidth,
        textLength: item.textLength || 23,
        icon: item.icon,
        iconTooltip: item.iconTooltip,
        list: item.list,
        type: item.type,
        show: item.show !== false,
        setOnMount: item.setOnMount,
        options: item.options || [],
      }));
    },
    mapItems(items) {
      items = items.map((item, index) => {
        item.selected =
          !!this.selected.find((a) => a.index == index) || item.selected;
        item.order_no = item.order_no;
        item.loading = item.loading;

        // Row Item
        var row = {
          row: item,
          details: [],
          index,
          buttons: [],
          class: this.rowClass,
        };

        // Get Provided Columns
        this.columns.forEach((column, index2) => {
          row.details.push({
            // Item Column Name
            name: column.name,
            // Table Header Title
            column: column,
            // Provided Value
            value: item[column.name],
            // Decide Value Depending On Whether Render Method Is Provided
            rendered: column.render
              ? column.render(item, item[column.name], index)
              : item[column.name],
            // Origin Item Row
            row: item,
            // Whether Or Not To Display Item
            show: column.show !== false,
            // Click Event For Column
            click: column.click,
          });
        });

        // Get Provided Actions
        this.actions.forEach((button, index3) => {
          row.buttons.push({
            // Spread Provided Button Properties
            ...button,
            // Decide Visibility Depending On Whether Show Method Is Provided
            // Default: true
            show: button.show ? button.show(item, index) : true,
            disabled: button.disabled ? button.disabled(item, index) : false,
          });
        });

        return row;
      });
      return items;
    },
    click(row, cell, name, index) {
      if (this.onClick) {
        this.onClick(...arguments);
      }
    },
    columnClick(action, row, cell, name, index) {
      if (action) {
        action(row, cell, name, index);
      }
    },
    selectAll(event) {
      this.selected = [];
      this.renderedItems.forEach((item) => {
        item.row.selected = false;
      });

      if (event.target.checked) {
        this.renderedItems.forEach((item) => {
          if (this.selected.length < this.selectLimit) {
            item.row.selected = true;
            this.selected.push(item);
          }
        });
      }
      this.$emit("on-checked", this.selectedItems());
    },
    selectedItems() {
      return helper.arrayPluck(this.selected, "row");
    },
    select(item) {
      var index = this.selected.findIndex((a) => a.index == item.index);
      if (index > -1) {
        item.row.selected = false;
        this.selected.splice(index, 1);
      } else {
        item.row.selected = true;
        this.selected.push(item);
      }

      this.$emit("on-checked", this.selectedItems());
    },

    // Alerts
    // success(success = "Success") {
    //   toastr.success(success);
    // },
    // error(error = "Error") {
    //   toastr.error(error);
    // },

    // Trigger from child component --> Filters
    onColumnSearch(data) {
      if (data.column) {
        this.doSearch(data.value, this.searchDelay, data.column);
      }
    },
    makeRequest(pageNumber) {
      if (this.loading) return;

      let requestData = {};

      if (this.sortColumn && this.sortColumn !== "#") {
        requestData.sort_by = this.sortColumn;
        requestData.sort_dir = this.asc ? "ASC" : "DESC";
      }

      if (this.searchQuery && this.searchQuery.length >= 3) {
        requestData.search_query = this.searchQuery;
      }

      if (this.languageFilter) requestData.lang = this.languageFilter;
      else requestData.lang = "tr";

      var localFilter = null;

      // filtre cache
      if (this.componentName) {
        var localpage = localStorage.getItem(
          `last_${this.componentName}_current_page`
        );
        if (localpage) {
          this.currentPage = localpage;
        } else {
          localpage = pageNumber || this.currentPage || 1;
        }
        requestData.page = localpage;
      } else {
        requestData.page = pageNumber || this.currentPage || 1;
      }

      requestData.limit = this.localPerPage || 25;
      // let queryString = helper.makeQueryString(requestData);
      // let requestURL = `${constant.API_URL + this.url}?${queryString}`;
      // let sessionStorage = JSON.parse(localStorage.session);
      this.$emit("make-request", requestData);

      // Get Data From Server Using Ajax
      // Axios.get(requestURL, {
      //   headers: {
      //     Authorization: sessionStorage
      //       ? "Bearer " + sessionStorage.accessToken
      //       : null,
      //   },
      // })
      //   .then((response) => {
      //     let result = response.data;

      //     this.localPerPage = result.item_count || 25;
      //     this.currentPage = result.current_page;
      //     this.totalPages = result.total_page;
      //     this.totalRecords = result.total_items;

      //     this.$emit("update-list", response.data.items);
      //     // this.success("Data Loaded");
      //   })
      //   .catch((error) => {
      //     // this.error(error || "Unable To Load Data");
      //   })
      //   .then(() => {
      //     this.ajaxLoading = false;
      //   });
    },
  },
  computed: {
    getCurrentTotal() {
      let result = 0;
      const maxValue = this.tableTotal;
      const perPage = this.localPerPage;

      if (this.paginatedItems && this.paginatedItems.length) {
        this.paginatedItems.forEach((item) => {
          result += Number(item.row[this.totalColumn]);
        });
      }

      return result;
    },
    getTotalRecord() {
      if (this.ajax) return this.totalRecords;

      return this.renderedItems.length;
    },
    getModal() {
      return this.paginatedItems[this.showIndex].row.media;
    },
    totalPages() {
      return this.tableResult.total_page;
    },
    totalRecords() {
      return this.tableResult.total_items;
    },
    // Total Number Of Pages For Pagination
    pages() {
      if (this.ajax) return this.totalPages;

      if (this.getTotalRecord > this.localPerPage) {
        return Math.ceil(this.getTotalRecord / this.localPerPage);
      } else {
        return 0;
      }
    },
    // Array Of Links With Page Number For Pagination
    paginateLinks() {
      var links = [];
      var approved = [];
      let center = Math.round(this.pages / 2) - 1;
      for (var i = 0; i < this.pages; i++) {
        if (this.pages > 6) {
          let difference = this.currentPage - i;
          let centerDifference = center - i;
          // around the current page
          if (!(difference < 0) && !(difference > 2)) {
            // around the center
          } else if (i === center) {
            // at the start or end
          } else if (this.pages - i <= 2 || i <= 1) {
            // everywhere else
          } else {
            continue;
          }
        }
        links.push({ page: i + 1 });
      }
      return links;
    },
    showFilters() {
      return Object.keys(this.filters).length > 0;
    },
  },
  watch: {
    currentPage(newValue) {
      this.cacheCurrentPage(newValue);
      if (!this.ajax) {
        this.paginatedItems = this.renderedItems.slice(
          this.localPerPage * (newValue - 1),
          this.localPerPage * newValue
        );
      } else {
        this.makeRequest(newValue);
      }
    },
    pageNumber(newValue) {
      this.pageNumber = helper.numberInput(newValue) || 1;
    },
    localPerPage(newValue) {
      if (!this.ajax) {
        this.start();
      } else {
        this.makeRequest(1);
      }
      this.paginatedItems = this.renderedItems.slice(
        newValue * (this.currentPage - 1),
        newValue * this.currentPage
      );
    },
    items(newValue) {
      this.getHeaders();

      if (this.selectable) {
        this.selected = [];
      }
      this.renderedItems = this.mapItems(newValue);

      // Get All Items In Current Page
      this.paginatedItems = this.renderedItems.slice(
        this.localPerPage * (this.currentPage - 1),
        this.localPerPage * this.currentPage
      );

      if (!this.ajax) this.asc = true;

      if (this.allowSortIndex) {
        this.sortIndex();
      }
    },
    data(newValue) {
      this.items = newValue;
    },
    renderedItems(newValue) {
      if (!this.ajax) {
        if (this.paginatable) {
          this.paginatedItems = newValue.slice(
            this.localPerPage * (this.currentPage - 1),
            this.localPerPage * this.currentPage
          );
        } else {
          this.paginatedItems = newValue;
        }
      } else {
        this.paginatedItems = this.renderedItems;
      }
    },
    deep: true,
  },
  // Lifetime Events
  async mounted() {
    // Parse Headers
    this.getHeaders();

    // Set Default Sorting To Index
    // Asc will be converted to false so order will be in reverse
    this.asc = true;
    this.sortIndex();

    // Use Provided Data If Ajax Is Not Specified
    if (!this.ajax) {
      // Map Items From Provided Data
      this.items = this.data;
      // Get All Items In Current Page
      // this.paginatedItems = this.renderedItems.slice(this.localPerPage * (this.currentPage - 1), (this.localPerPage * this.currentPage));
    } else {
      this.start();
      if (this.fetchOnMount) {
        this.makeRequest();
      }
    }
  },
};
</script>

<style lang="scss">
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.page-link {
  user-select: none;
  cursor: pointer;
}
.table-fixed-head {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
  table {
    thead {
      tr {
        position: sticky;
        top: -0.1px;
        z-index: 1;
      }
    }
  }
  .table-bottom {
    width: 100%;
    padding-top: 20px;
    margin: 0;
    background-color: #fff;
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
}
.data-table {
  // font-size: 14px
  &-loading {
    align-items: center;
    display: flex;
    height: 200px;
    flex-flow: column;
    justify-content: center;
    position: relative;
    width: 100%;
    &-spinner {
      animation: spin 1s linear infinite;
      border-radius: 999px;
      border: 2px solid #007bff;
      border-top-color: transparent;
      content: "";
      height: 75px;
      margin-bottom: 15px;
      width: 75px;
    }
    &-text {
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  &-control {
    .custom-select {
      width: initial;
    }
  }

  .table {
    &-responsive {
      margin-bottom: 10px;
      &::-webkit-scrollbar {
        -webkit-apperance: none;
        height: 15px;
        width: 15px;
        &-track {
          background: #eee;
          border-radius: 999px;
        }
        &-thumb {
          background: #ccc;
          border-radius: 999px;
          border: 3px solid #eee;
          &:focus {
            background: #ccc;
          }
        }
      }
    }
    &.straight {
      white-space: nowrap;
    }
    thead {
      th {
        font-weight: 500;
        font-size: 0.85rem;
        &.sortable {
          cursor: pointer;
          padding-right: 30px;
          position: relative;
          &:before,
          &:after {
            border: 5px solid transparent;
            content: "";
            display: block;
            opacity: 0.3;
            position: absolute;
            right: 10px;
          }
          &:before {
            border-bottom-color: currentColor;
            top: 10px;
          }
          &:after {
            bottom: 10px;
            border-top-color: currentColor;
          }
        }
        &.sort {
          font-weight: 700;
          &.asc {
            &:before {
              opacity: 1;
            }
          }
          &.desc {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
    tbody {
      tr {
        transition: all 200ms;
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
        &.clickable {
          cursor: pointer;
        }
      }
      td {
        vertical-align: middle;
        font-size: 0.8rem;
      }

      &-filters {
        margin-bottom: 15px;
      }
      &-filter {
        background: #fff;
        border-radius: 3px;
        cursor: pointer;
        color: #777;
        display: inline-block;
        font-size: 12px;
        padding: 5px 15px;
        margin: 0 0 3px 3px;
        &:hover {
          background: #aaa;
          color: #fff;
        }
        &.active {
          background: #337ab7;
          color: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
  }

  table td {
    border-top-width: 0px;
    display: block;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: none !important;
    .mini-gallery-container {
      margin: 0;
    }
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
