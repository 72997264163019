<template>
  <td>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        v-bind:id="componentId + '-value'"
        ref="checkbox"
        v-bind:checked="row.selected"
        v-bind:disabled="isDisabled"
        v-on:input="setCheckboxValue"
      />
      <label
        class="custom-control-label"
        v-bind:for="componentId + '-value'"
        ref="label"
      >
      </label>
    </div>
  </td>
</template>
<script>
export default {
  name: "TableCheckbox",
  props: {
    rowIndex: {
      default: 0,
    },
    row: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Array,
      default: () => [],
    },
    selectLimit: {
      default: "25",
    },
  },
  computed: {
    componentId() {
      return (
        "wetable-content-row-cell-checbox-" + helper.getRandomInteger(1, 999999)
      );
    },
    isDisabled() {
      return this.selected.length >= this.selectLimit && !this.row.selected;
    },
  },
  methods: {
    setCheckboxStatus(value) {
      if (this.$refs.checkbox) {
        this.$refs.checkbox.selected = value;
      }
    },
    setCheckboxValue(e) {
      this.$parent.$emit("on-checked", this.row);
    },
  },
};
</script>
