<template>
  <div v-if="computedData && computedData.length">
    <div class="btn-group" role="group">
      <button
        v-for="btn in computedData"
        v-bind:key="btn.name"
        v-bind:class="['btn export-btn', btn.btnClass]"
        v-tooltip="getTooltipConf(btn)"
        v-on:click="onClick(btn)"
        type="button"
      >
        <i
          v-bind:class="[btn.loading ? 'fas fa-spinner fa-spin' : btn.icon]"
        ></i>
        <span v-if="btn.showTitle" class="ml-2">{{ btn.title }}</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Exports",
  props: {
    data: {
      default: () => [],
    },
  },
  data() {
    return {
      exportables: [
        {
          name: "excel",
          title: "Excel'e Aktar",
          icon: "fas fa-file-excel",
          btnClass: "excel",
          extension: "xlsx",
          loading: false,
        },
        {
          name: "pdf",
          title: "PDF'e Aktar",
          icon: "fas fa-file-pdf",
          btnClass: "pdf",
          extension: "pdf",
          loading: false,
        },
        {
          name: "csv",
          title: "CSV'e Aktar",
          icon: "fas fa-file-csv",
          btnClass: "excel",
          extension: "csv",
          loading: false,
        },
        {
          name: "print",
          title: "Yazdır",
          icon: "fas fa-print",
          btnClass: "btn-outline-secondary",
        },
        {
          name: "bulk-print",
          title: "Seçilenleri Yazdır",
          icon: "fas fa-print",
          btnClass: "btn-outline-info",
          showTitle: false,
          loading: false,
        },
      ],
    };
  },
  methods: {
    onClick(btn) {
      this.$parent.$emit("on-export", btn);
    },
    getTooltipConf(btn) {
      if (!btn.showTitle) {
        return {
          content: btn.title,
          placement: "bottom",
        };
      }
    },
  },
  computed: {
    computedData() {
      let result = [];

      if (this.data && this.data.length) {
        for (let i = 0; i < this.data.length; i++) {
          const element = this.data[i];
          const existedExport = this.exportables.find(
            (item) => item.name == element
          );
          if (existedExport) {
            result.push(existedExport);
          }
        }
      }
      return result;
    },
  },
};
</script>
<style lang="scss">
.export-btn {
  height: 38px;
  padding-top: 8px;
  min-width: 40px;
  border-color: #ced4da;
  &.excel {
    color: #1d6f42;
    &:hover {
      background-color: #1d6f42;
      border-color: #1d6f42;
      color: white;
    }
  }
  &.pdf {
    color: #d12424;
    &:hover {
      background-color: #d12424;
      border-color: #d12424;
      color: white;
    }
  }
}
</style>