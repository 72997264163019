<template>
  <tr
    v-bind:draggable="draggable"
    v-on:dragstart="startDrag"
    v-on:dragover.prevent="onDragOver"
    v-on:dragleave.prevent="onDragLeave"
    v-on:drop="onDrop"
    v-on:dragenter.prevent
    v-bind:class="[
      item.class,
      item.row.selected ? 'alert-primary' : '',
      item.row && item.row.status == 'error' ? 'bg-danger-light' : '',
    ]"
    ref="dropzone"
    v-bind:style="item.row.status == 'İptal Edildi' ? 'background-color: rgba(220, 53, 69, 0.06)' : ''"
  >
    <TableCheckbox
      v-if="selectable"
      v-bind:row="item.row"
      v-bind:selected="selected"
      v-bind:select-limit="selectLimit"
    />

    <!-- Draggable icon -->
    <td v-if="draggable" width="3%" class="position-relative">
      <span
        class="p-2 bg-light position-absolute border-right"
        style="top: 0; left: 0; height: 100%"
      >
        <i class="fas fa-ellipsis-v text-secondary mt-3"></i>
      </span>
    </td>
    <!-- Draggable icon -->

    <!-- Display All Parsed Values -->
    <Cell
      v-for="(td, i) in item.details"
      v-bind:key="i"
      v-bind:detail-index="i"
      v-bind:row-index="index"
      v-bind:productColumn="productColumn"
      v-bind:rows.sync="rows"
    />

    <!-- Diplay Actions If Provided -->
    <td
      v-if="item.row.hasOwnProperty('buttons') && item.row.buttons === false"
    ></td>
    <td
      v-else-if="item.buttons.length"
      data-label="İşlemler"
      class="hide-on-print"
    >
      <!-- Loop Through All Provided Actions -->
      <template v-for="(button, j) in itemButtons">
        <a
          v-if="
            button.action == 'product-edit' || button.action == 'package-edit'
          "
          v-bind:key="j"
          v-bind:href="
            (button.action == 'product-edit'
              ? 'products/detail/'
              : 'packages/detail/') + item.row.id
          "
          class="btn btn-sm"
          v-bind:class="button.class"
        >
          <i v-if="button.icon" v-bind:class="button.icon"></i>
          <span v-if="button.text">{{ button.text }}</span>
        </a>
        <template v-else>
          <button
            type="button"
            class="btn btn-sm"
            v-bind:class="button.class"
            v-bind:key="j"
            v-on:click="onAction(button.action, item.row)"
            :disabled="button.disabled"
            v-if="button.action !== 'children'"
            v-tooltip="getTooltip(button)"
          >
            <i v-if="button.icon" v-bind:class="button.icon"></i>
            <span v-if="button.text">{{ button.text }}</span>
          </button>
          <button
            v-else
            v-bind:key="j"
            v-on:click="onAction(button.action, item.row)"
            :disabled="button.disabled"
            v-tooltip.bottom="childrenTooltip(button)"
            class="btn btn-sm stack-btn action-btn"
            v-bind:class="{ empty: !countChildren }"
          >
            <i class="fas fa-sort-amount-down fa-flip-horizontal"></i>
            <span>{{ countChildren }}</span>
          </button>
        </template>
      </template>
    </td>
  </tr>
</template>

<script>
import Cell from "./components/Cell/Index";
import TableCheckbox from "./components/TableCheckbox/Index";

export default {
  name: "Rows",
  props: {
    productColumn: {
      tpye: String,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    selectLimit: {
      default: "25",
    },
    rows: {
      type: Array,
      defautl: () => [],
    },
    index: {
      default: 0,
    },
  },
  methods: {
    onAction(action, row) {
      if (action == "remove" && row.hasOwnProperty("deleted")) {
        row.deleted = 1;
      }

      const data = {
        row: row,
        index: this.index,
        key: action,
      };

      if (action == "children") {
        if (this.countChildren) {
          this.$parent.$emit("on-action", data);
        }
      } else {
        this.$parent.$emit("on-action", data);
      }
    },
    childrenTooltip(button) {
      return this.countChildren
        ? this.countChildren + " " + button.childrenText + " mevcut"
        : button.childrenText + " mevcut değil";
    },
    getTooltip(button) {
      if (button.tooltip) {
        const result = {
          content: button.tooltip,
          placement: "bottom",
        };

        return result;
      } else {
        return;
      }
    },
    dropzoneHighlight(unhighlight) {
      const dropzone = this.$refs.dropzone;

      if (!unhighlight) {
        dropzone.classList.add("highlight");
      } else {
        dropzone.classList.remove("highlight");
      }
    },
    startDrag(e) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("rowIndex", this.index);
    },
    onDragOver(e) {
      this.dropzoneHighlight();
    },
    onDragLeave() {
      this.dropzoneHighlight(true);
    },
    onDrop(e) {
      const getIndex = e.dataTransfer.getData("rowIndex");
      const draggedElement = this.rows[getIndex].row;
      const tmpDragged = helper.clone(draggedElement);

      const targetElement = this.rows[this.index].row;

      draggedElement.order_no = targetElement.order_no;
      targetElement.order_no = tmpDragged.order_no;

      const data = [
        {
          id: draggedElement.id,
          order_no: draggedElement.order_no,
        },
        {
          id: targetElement.id,
          order_no: targetElement.order_no,
        },
      ];

      this.$parent.$emit("order-changed", data);
      this.dropzoneHighlight(true);
    },
  },
  components: {
    Cell,
    TableCheckbox,
  },
  computed: {
    item() {
      return this.rows[this.index];
    },
    countChildren() {
      const row = this.item.row;
      return row && row.childCount ? row.childCount : 0;
    },
    itemButtons() {
      return [...this.item.buttons, ...(this.item.row.buttons || [])];
    },
  },
  mounted() {
    this.dropzoneHighlight(true);
  },
};
</script>
<style lang="scss" scoped>
.highlight {
  border-color: #2ed573;
  background-color: #2ed5742f;
}
.btn-sm {
  min-width: 36px;
}
</style>
