<template>
  <td>
    <FilterDate v-if="column.type == 'date'" v-bind:column="column" />
    <FilterInput v-if="column.type == 'string'" v-bind:column="column" />
    <template v-else>
      {{ emptyText }}
    </template>
  </td>
</template>
<script>
import FilterDate from "./components/FilterDate/Index";
import FilterInput from "./components/FilterInput/Index";
export default {
  name: "Filters",
  props: {
    column: {
      type: Object,
      default: () => [],
    },
  },
  components: {
    FilterDate,
    FilterInput,
  },
  computed: {
    emptyText() {
      switch (this.column.type) {
        case "image":
          return;
        default:
          return;
      }
    },
  },
};
</script>
