<template>
  <WeInput
    v-on:input="onInput"
    class="mb-0"
    v-bind:prepend="filterIcon"
    v-bind:small-size="true"
  />
</template>
<script>
export default {
  name: "FilterInput",
  props: {
    column: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filterIcon: "<i class='fas fa-filter fa-sm'></i>",
    };
  },
  methods: {
    onInput(value) {
      const data = {
        column: this.column.name,
        value: value,
      };
      this.$parent.$emit("on-search", data);
    },
  },
};
</script>
