<template>
  <td
    v-bind:data-label="column.th"
    v-bind:class="getClass"
    v-bind:style="getStyle"
    v-tooltip="getTooltip"
  >
    <TableSkeletonLoader
      v-if="getLoadingStatus"
      v-bind:class="getLoadingClass"
    />
    <TableImageUpload
      v-else-if="column.type == 'image_upload'"
      v-bind:row-index="rowIndex"
      v-bind:row="rowData"
      v-bind:value="cellValue"
    />
    <TableImage
      v-else-if="column.type == 'image'"
      v-bind:source="imagePath"
      v-bind:hidden="true"
    />
    <TableImage
      v-else-if="column.type == 'image_url'"
      v-bind:source="cellText"
      v-bind:width="column.image_width || '80'"
      v-bind:type="'picture'"
    />
    <TableList
      v-else-if="column.type == 'list'"
      v-bind:class-name="cellText"
      v-bind:data-title="cellTitle"
    />
    <TableSwitch
      v-else-if="column.type == 'boolean'"
      v-bind:row="rowData"
      v-bind:column="column"
      v-bind:value="cellValue"
    />
    <TableBooleanDisabled
      v-else-if="column.type == 'boolean_disabled'"
      v-bind:value="cellValue"
    />
    <StockStatus
      v-else-if="column.type == 'stock_status'"
      v-bind:value="cellValue"
    />
    <TableQuantity
      v-else-if="column.type == 'quantity'"
      v-bind:row="rowData"
      v-model="cellValue"
    />
    <TableInput
      v-else-if="column.type == 'input'"
      v-bind:row="rowData"
      v-bind:row-index="rowIndex"
      v-bind:size="column.size || ''"
      v-bind:filter-number="column.filterNumber"
      v-model="cellValue"
    />
    <TablePriceTooltip
      v-else-if="
        column.type == 'currency' &&
        ((rowData.hasOwnProperty('converted_price') &&
          rowData.hasOwnProperty('converted_price_currency')) ||
          (rowData.status && rowData.status == 'error'))
      "
      v-bind:row="rowData"
      v-bind:current-price="rowData.price"
      v-bind:current-price-currency="rowData.currency_code.code"
      v-bind:converted-price="rowData.converted_price"
      v-bind:converted-price-currency="rowData.converted_price_currency"
    />
    <TablePrice
      v-else-if="column.type == 'price'"
      v-bind:column="column"
      v-bind:size="column.size || ''"
      v-model="cellValue"
    />
    <TableSelect
      v-else-if="column.type == 'select'"
      v-bind:row="rowData"
      v-model="cellValue"
      v-bind:options="column.options"
    />
    <TableStar v-else-if="column.type == 'star'" v-bind:value="cellValue" />
    <TableTotalPrice
      v-else-if="column.type == 'total_price'"
      v-bind:row="rowData"
      v-bind:value="cellValue"
    />
    <TableMarketplaceList
      v-else-if="column.type == 'marketplace_list'"
      v-bind:items="cellValue"
    />
    <TableLanguagelist
      class="ml-2"
      v-else-if="column.type == 'language'"
      v-bind:items="cellValue"
    />
    <TableSplitCount
      v-else-if="column.type == 'split_count'"
      v-bind:row="rowData"
    />
    <TableBadge
      v-else-if="column.type == 'table_badge'"
      v-bind:value="cellValue"
    />
    <TableEditPrice
      v-else-if="column.type == 'editable_currency'"
      v-bind:row="rowData"
      v-model="cellValue"
    />
    <div v-else-if="column.type == 'html'" v-html="cellText"></div>
    <template v-else>
      <template
        v-if="
          column.th == productColumn &&
          productColumn != null &&
          rowData.hasOwnProperty('main_product_id')
        "
      >
        <a
          class="table-product-name"
          v-bind:style="{
            maxWidth: column.maxWidth || '100%',
            wordBreak: 'break-word',
            whiteSpace: 'break-spaces',
          }"
          v-bind:href="`products/detail/${rowData.id}`"
        >
          {{ cellText }}
        </a>
        <a
          v-bind:style="{
            marginLef: '10px',
            maxWidth: column.maxWidth || '100%',
            wordBreak: 'break-word',
            whiteSpace: 'break-spaces',
          }"
          v-bind:href="
            `${config['site.url']}` +
            (config['site.slug_prefix'] == '1' ? '/urun/' : '/') +
            `${rowData.slug}`
          "
          target="_blank"
        >
          <i class="fas fa-external-link-alt"></i>
        </a>
      </template>
      <template v-else-if="column.name == 'order_no'">
        <div style="max-width: 330px">
          <p class="table-order-p">{{ cellText }}</p>
        </div>
      </template>
      <template v-else>
        {{ cellText }}
      </template>
    </template>
  </td>
</template>
<script>
const TableImage = () => import("./components/TableImage/Index");
const TableList = () => import("./components/TableList/Index");
const TableSwitch = () => import("./components/TableSwitch/Index");
const TableQuantity = () => import("./components/TableQuantity/Index");
const TableEditPrice = () => import("./components/TableEditPrice/Index");
const TableInput = () => import("./components/TableInput/Index");
const TableImageUpload = () => import("./components/TableImageUpload/Index");
const TableSelect = () => import("./components/TableSelect/Index");
const TableStar = () => import("./components/TableStar/Index");
const TablePrice = () => import("./components/TablePrice/Index");
const TablePriceTooltip = () => import("./components/TablePriceTooltip/Index");
const TableTotalPrice = () => import("./components/TableTotalPrice/Index");
const TableBooleanDisabled = () =>
  import("./components/TableBooleanDisabled/Index");
const StockStatus = () => import("./components/StockStatus/Index");
const TableMarketplaceList = () =>
  import("./components/TableMarketplaceList/Index");
const TableSplitCount = () => import("./components/TableSplitCount/Index");
const TableSkeletonLoader = () =>
  import("./components/TableSkeletonLoader/Index");
const TableBadge = () => import("./components/TableBadge/Index.vue");
const TableLanguagelist = () => import("./components/TableLanguageList/Index");
import { mapActions, mapState } from "vuex";

export default {
  name: "Cell",
  props: {
    value: {
      default: () => "",
    },
    rows: {
      type: Array,
      default: () => [],
    },
    rowIndex: {
      default: 0,
    },
    detailIndex: {
      default: 0,
    },
    productColumn: {
      type: String,
      default: null,
    },
    align: {
      type: String,
      default: () => "",
    },
  },
  components: {
    TableImage,
    TableList,
    TableSwitch,
    TableQuantity,
    TableInput,
    TableImageUpload,
    TableSelect,
    TableStar,
    TableTotalPrice,
    TablePrice,
    TablePriceTooltip,
    TableBooleanDisabled,
    StockStatus,
    TableMarketplaceList,
    TableSplitCount,
    TableSkeletonLoader,
    TableBadge,
    TableLanguagelist,
    TableEditPrice
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    getClass() {
      let result = "";

      switch (this.column.type) {
        case "input":
        case "boolean":
        case "boolean_disabled":
        case "stock_status":
        case "list":
        case "icon":
        case "image_url":
        case "table_badge":
          if (this.align) {
            result = "text-" + this.align;
          } else {
            result = "text-center";
          }
          break;
        case "integer":
        case "float":
        case "currency":
        case "total_price":
        case "date":
        case "time":
        case "datetime":
          if (this.align) {
            result = "text-" + this.align;
          } else {
            result = "text-right";
          }
          break;
        case "html":
          result = "";
          break;
        case "select":
          result = "position-relative";
          break;
        case "image":
        default:
          if (this.align) {
            result = "text-" + this.align;
          } else {
            result = "text-left";
          }
          break;
      }

      if (this.column.maxWidth && this.column.maxWidth.length) {
        result += " text-truncate";
      }

      return result;
    },
    getStyle() {
      if (this.column.maxWidth && this.column.maxWidth.length) {
        return {
          maxWidth: this.column.maxWidth + "px",
        };
      }
    },
    getTooltip() {
      if (
        this.column.maxWidth &&
        this.column.maxWidth.length &&
        this.cellValue &&
        this.cellValue.length > Number(this.column.textLength)
      ) {
        return {
          content: this.cellValue,
          placement: "bottom",
        };
      }
    },
    row() {
      return this.rows[this.rowIndex];
    },
    rowData() {
      return this.row.row;
    },
    rowDetail() {
      return this.row.details[this.detailIndex];
    },
    column() {
      return this.rowDetail.column;
    },
    cellValue: {
      get() {
        return this.rowDetail.value;
      },
      set(value) {
        this.rowData[this.column.name] = value;
        this.rowDetail.value = value;
      },
    },
    imagePath() {
      let image = this.rowData.image;
      switch (typeof image) {
        case "string":
          return image;

        default:
          return image && image.path && image.path.medium
            ? image.path.medium
            : "";
      }
    },
    cellText() {
      return localization.localize(
        this.cellValue,
        this.column.type,
        this.column.list,
        false,
        this.rowData.currency_code ? this.rowData.currency_code.code : null
      );
    },
    cellTitle() {
      let result = "";

      if (this.column.type == "list") {
        let list = [];
        if (this.column.list) {
          list = this.column.list.filter((x) => x.key == this.cellValue);
          if (list && list.length) {
            result = list[0].title;
          }
        }
      }

      return result;
    },
    getLoadingStatus() {
      return (
        this.rowData &&
        this.rowData.loading &&
        ["currency", "stock_status", "datetime"].includes(this.column.type)
      );
    },
    getLoadingClass() {
      let result = "ml-auto";
      switch (this.column.type) {
        case "stock_status":
          result = "mx-auto";
          break;
      }

      return result;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.table-product-name {
  color: black;
}
.table-order-p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
</style>