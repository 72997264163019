<template>
  <li v-if="availableLocales.length > 1" class="lang-button nav-item mr-2 form-control">
    <a
      class="nav-link dropdown-toggle"
      v-bind:title="$t('language')"
      data-toggle="dropdown" 
    >
      <lang-flag v-bind:iso="selectedLang" />
    </a>
    <div id="dropdownMenu" class="dropdown-menu">    
      <div
        v-bind:class="[
          'dropdown-item',
           lang == selectedLang ? 'active' : ''
        ]"
        v-for="(lang, i) in availableLocales"
        :key="`lang-${i}`"
        v-on:click.prevent="onChange(lang)"
      > 
      <div v-if="lang" >        
        <lang-flag v-bind:iso="lang" />
      </div>        
      </div>
    </div>
  </li>
</template>
<script>
import LangFlag from "vue-lang-code-flags";
import { mapState } from 'vuex';

export default {
  name: "Language",
  components: { LangFlag },
  props: {
    stateName: {
      type: String,
      default: ""
    }
  },
  data() {
    return { 
      langs: [],
      selectedLang: "tr"
    }
  },
  computed: {
    ...mapState(["session"]),
    availableLocales() {
      let locales = this.session?.config["site.available_locales"];

      if (locales) locales = JSON.parse(locales)
      else locales = ["tr"]      

      return locales;
    },
  },
  methods: {
    onChange(lang) {
      this.selectedLang = lang
      this.$emit('FilterLanguage', this.selectedLang)

      var element = document.getElementById("dropdownMenu");
      element.classList.remove("show");
    },
  }
};
</script>

<style lang="scss" scoped>
.lang-button {
  display: flex;
  justify-content: center;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.dropdown-item span {
  margin-left: 4px;
}

.dropdown-toggle::after {
  margin-left: 10px
}

.form-control {
  padding: 0;
  width: auto;
}

a {
  color: #000000;
}

.dropdown-menu{
  min-width: 72px;
}
</style>